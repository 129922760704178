import Vue from 'vue';
import Router from 'vue-router';

const Login = () => import('@/pages/Login');
const Home = () => import('@/pages/Home');
const SteelWeights = () => import('@/pages/SteelWeights');
const Cowin = () => import('@/pages/Cowin');

Vue.use(Router);

      async function digestMessage(message) {
        const msgUint8 = new TextEncoder().encode(message);                           // encode as (utf-8) Uint8Array
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);           // hash the message
        const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
        return hashHex;
      }

const router = new Router({
	mode: 'history',
	routes: [
		{
			path: '/',
			name: 'root',
			redirect: {
				name: "login"
			}
		},
		{
			path: '/login',
			name: 'login',
			component: Login
		},
		{
			path: '/home',
			name: 'home',
			component: Home,
			children: [
				{
					path: '/utilities/steel-weights',
					name: 'h-u-steel-weights',
					component: SteelWeights
				},
				{
					path: '/utilities/cowin',
					name: 'h-u-cowin',
					component: Cowin
				}
			]
		},
		{
			path: '*',
			redirect: {
				name: 'login',
			},
		}
	],

})

router.beforeEach(async (to, from, next) =>{
	const whitelisted = [ "login", "h-u-cowin" ]
	let authCookie = Vue.$cookies.get("akshatsachdeva-authentication");
	let isAuthenticated = false;
	if(authCookie && await digestMessage(authCookie) == 'af26b6dc9a094d47e174d102e80c5a3fd4227c00a5c8977ddb82c3085ac08741') {
		isAuthenticated=true;
	}
  if (!whitelisted.includes(to.name) && !isAuthenticated) next({ name: 'login' })
  else next();
}
);


export default router;