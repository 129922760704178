<template>
  <div id="app">
    <Header/>
    <Banner/>
  <router-view></router-view>
  </div>
</template>

<script>

  export default {
    name: 'app',
    components: {
      Header: () => import('@/components/Header'),
      Banner: () => import('@/components/Banner'),
    }
  }
  export const globalData = {
    state: {}
  }
  
</script>

<style>
#app {
  margin-top: 4rem;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #0af;
}

button.btn.custom-button.btn-secondary.btn-block {
  margin: 1rem;
  color: #0af;
  border-color: #0af;
  font-weight: bold;
  background: none;
  border-radius: 2px;
}

button.btn.custom-button.btn-secondary.btn-block:hover {
    background-color: #0af;
    color: white;
    font-weight: bold;
    box-shadow: 2px 2px 6px 2px #0af;
    border-color: #0af;
  }

.content-header {
  font-family: system-ui;
  font-size: 1.9rem;
  font-variant: all-small-caps;
  color: darkslategrey;
  margin-bottom: 0px;
  font-weight: 500;
}

.content-container.container {
  margin-top: 2rem;
}

@media (min-width: 768px) {
  .content-container.container {
      max-width: 540px;
  }
}

@media (max-width: 398px) {
  .app-container {
      padding: 48px;
  }
}

.error-block.d-block{
  font-size: medium;
}

.alert-block.d-block{
    color: #c90;
    font-weight: bold;
    text-transform: uppercase;
    font-size: medium;
    margin: 0.3rem;
    border-color: #ced4da;
    border-radius: 2px;
    border-style: solid;
    border-width: 1px;
    padding: 0.6rem;
}

.col-form-label {
  font-family: system-ui;
}
</style>
