import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue';
import Vuelidate from 'vuelidate';
import VueCookies from 'vue-cookies';

import App from '@/App.vue'
import Router from '@/router/index'

import 'bootstrap/dist/css/bootstrap.css'

Vue.config.productionTip = false
Vue.use(Vuelidate);
Vue.use(VueCookies);
Vue.use(BootstrapVue);

Vue.mixin({
  methods: {
    toggleHex: function() {
        document.getElementsByTagName('body')[0].classList.toggle('open');
      },
    openShutter: function() {
      document.getElementsByTagName('body')[0].classList.add('open');
    }
  },
})

new Vue({
	el: "#app",
	router: Router,
	render: h => h(App),
}).$mount('#app')
